var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-2" }, [
    _c(
      "div",
      [
        _c("b-table", {
          attrs: {
            busy: _vm.isLoading,
            "head-variant": "light",
            "no-local-sorting": true,
            items: _vm.items,
            fields: _vm.getAllFields,
            "current-page": _vm.currentPage,
            "per-page": "0",
            "sort-by": _vm.sortBy,
            "sort-desc": _vm.sortDesc,
            "sort-direction": _vm.sortDirection,
            "sticky-header": _vm.height,
            "no-border-collapse": "",
            bordered: "",
            responsive: "",
            "show-empty": "",
            "empty-text": "Non ci sono informazioni da mostrare",
            small: "",
          },
          on: {
            "update:sortBy": function ($event) {
              _vm.sortBy = $event
            },
            "update:sort-by": function ($event) {
              _vm.sortBy = $event
            },
            "update:sortDesc": function ($event) {
              _vm.sortDesc = $event
            },
            "update:sort-desc": function ($event) {
              _vm.sortDesc = $event
            },
            "sort-changed": _vm.sortingChanged,
          },
          scopedSlots: _vm._u(
            [
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center my-2" },
                      [
                        _c("base-icon", {
                          attrs: { name: "loading", width: "35", height: "35" },
                        }),
                        _vm._v(" "),
                        _c("p", [_vm._v("Operazione in corso...")]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              _vm.items.length
                ? {
                    key: "cell(customer)",
                    fn: function ({ item }) {
                      return [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.toCustomer(item)) },
                        }),
                      ]
                    },
                  }
                : null,
              _vm.items.length
                ? {
                    key: "cell(insurance_policy)",
                    fn: function ({ item }) {
                      return [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.toPolicy(item)) },
                        }),
                      ]
                    },
                  }
                : null,
              _vm.items.length
                ? {
                    key: "cell(book_tag)",
                    fn: function ({ item }) {
                      return [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.toBookTag(item)) },
                        }),
                      ]
                    },
                  }
                : null,
              _vm.items.length
                ? {
                    key: "cell(company)",
                    fn: function ({ item }) {
                      return [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.toCompany(item)) },
                        }),
                      ]
                    },
                  }
                : null,
              _vm.items.length
                ? {
                    key: "cell(broker)",
                    fn: function ({ item }) {
                      return [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.toBroker(item)) },
                        }),
                      ]
                    },
                  }
                : null,
              _vm.items.length
                ? {
                    key: "cell(branch_risk)",
                    fn: function ({ item }) {
                      return [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(_vm.toBranchAndRisk(item)),
                          },
                        }),
                      ]
                    },
                  }
                : null,
              _vm.items.length
                ? {
                    key: "cell(delta)",
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.toLocaleCurrency(
                                item.agency_prov_purchase +
                                  item.agency_prov_take -
                                  (item.purchase_commission +
                                    item.take_commission)
                              )
                            ) +
                            "\n      "
                        ),
                      ]
                    },
                  }
                : null,
              _vm.items.length
                ? {
                    key: "cell(deferred)",
                    fn: function ({ item }) {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.toDeferred(item)) +
                              "\n        "
                          ),
                        ]),
                      ]
                    },
                  }
                : null,
              {
                key: "cell(purchase_commission)",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      {
                        class: {
                          coin:
                            item.agency_prov_purchase !=
                            item.purchase_commission,
                        },
                        attrs: {
                          title:
                            "Valore originale: " + item.agency_prov_purchase,
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.toLocaleCurrency(item.purchase_commission)
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(take_commission)",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      {
                        class: {
                          coin: item.agency_prov_take != item.take_commission,
                        },
                        attrs: {
                          title: "Valore originale: " + item.agency_prov_take,
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.toLocaleCurrency(item.take_commission)) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(tot_prod)",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.toLocaleCurrency(
                            parseFloat(
                              (
                                parseFloat(item.purchase_commission) +
                                parseFloat(item.take_commission)
                              ).toFixed(2)
                            )
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "bottom-row",
                fn: function (data) {
                  return _vm.includeBottomRow && _vm.items.length
                    ? _vm._l(data.fields, function (field, i) {
                        return _c("td", { key: i, staticClass: "totalRow" }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(i === 0 ? "Totale" : "") +
                                "\n            " +
                                _vm._s(
                                  i !== 0 && field.key === "gross"
                                    ? _vm.toLocaleCurrency(
                                        _vm.totalizeFields(_vm.items, field.key)
                                      )
                                    : null
                                ) +
                                "\n            " +
                                _vm._s(
                                  i !== 0 && field.key === "net"
                                    ? _vm.toLocaleCurrency(
                                        _vm.totalizeFields(_vm.items, field.key)
                                      )
                                    : null
                                ) +
                                "\n            " +
                                _vm._s(
                                  i !== 0 &&
                                    field.key === "agency_prov_purchase"
                                    ? _vm.toLocaleCurrency(
                                        _vm.totalizeFields(_vm.items, field.key)
                                      )
                                    : null
                                ) +
                                "\n            " +
                                _vm._s(
                                  i !== 0 && field.key === "agency_prov_take"
                                    ? _vm.toLocaleCurrency(
                                        _vm.totalizeFields(_vm.items, field.key)
                                      )
                                    : null
                                ) +
                                "\n            "
                            ),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  i !== 0 && field.key === "purchase_commission"
                                    ? _vm.toLocaleCurrency(
                                        _vm.totalizeFields(_vm.items, field.key)
                                      )
                                    : null
                                ) +
                                "\n            " +
                                _vm._s(
                                  i !== 0 && field.key === "take_commission"
                                    ? _vm.toLocaleCurrency(
                                        _vm.totalizeFields(_vm.items, field.key)
                                      )
                                    : null
                                ) +
                                "\n            " +
                                _vm._s(
                                  i !== 0 && field.key === "tot_prod"
                                    ? _vm.toLocaleCurrency(
                                        _vm.totalizeFields(_vm.items, [
                                          "purchase_commission",
                                          "take_commission",
                                        ])
                                      )
                                    : null
                                ) +
                                "\n          "
                            ),
                          ]),
                        ])
                      })
                    : undefined
                },
              },
              _vm.hasChecks && !_vm.isTableReadonly
                ? {
                    key: "head(check)",
                    fn: function () {
                      return [
                        _c("base-checkbox", {
                          staticClass: "check",
                          attrs: { name: "all", disabled: _vm.isTableReadonly },
                          on: { change: _vm.onSelectAllRows },
                          model: {
                            value: _vm.checkAll,
                            callback: function ($$v) {
                              _vm.checkAll = $$v
                            },
                            expression: "checkAll",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  }
                : null,
              _vm.hasChecks
                ? {
                    key: "cell(check)",
                    fn: function (row) {
                      return [
                        !_vm.isTableReadonly
                          ? _c("base-checkbox", {
                              staticClass: "check",
                              attrs: {
                                name: "check",
                                disabled: _vm.isTableReadonly,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.onSelectRow(
                                    $event,
                                    row.index,
                                    row.item
                                  )
                                },
                              },
                              model: {
                                value: row.item.check,
                                callback: function ($$v) {
                                  _vm.$set(row.item, "check", $$v)
                                },
                                expression: "row.item.check",
                              },
                            })
                          : _c("span", [
                              _vm._v(_vm._s(row.item.check ? "SI" : "NO")),
                            ]),
                      ]
                    },
                  }
                : null,
              {
                key: "cell(actions)",
                fn: function (row) {
                  return [
                    _c(
                      "div",
                      { staticClass: "action-buttons" },
                      [
                        _vm.actions.includes("infomodal")
                          ? _c(
                              "b-button",
                              {
                                staticClass: "mt-1 mr-1",
                                attrs: {
                                  size: "sm",
                                  variant: "lisaweb",
                                  title: "Modale Dettagli",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openModal(
                                      row.item,
                                      row.index,
                                      $event.target
                                    )
                                  },
                                },
                              },
                              [_c("b-icon", { attrs: { icon: "eye" } })],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.actions.includes("details")
                          ? _c(
                              "b-button",
                              {
                                staticClass: "mt-1 mr-1",
                                attrs: {
                                  size: "sm",
                                  variant: "lisaweb",
                                  title: row.detailsShowing
                                    ? "Chiudi dettagli"
                                    : "Apri dettagli",
                                },
                                on: { click: row.toggleDetails },
                              },
                              [
                                row.detailsShowing
                                  ? _c("b-icon", {
                                      attrs: { icon: "chevron-up" },
                                    })
                                  : _c("b-icon", {
                                      attrs: { icon: "chevron-down" },
                                    }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.actions.includes("edit") &&
                        _vm.isVarAccByCode(row.item, "CP")
                          ? _c(
                              "b-button",
                              {
                                staticClass: "mt-1 mr-1",
                                attrs: {
                                  size: "sm",
                                  variant: "lisaweb",
                                  title: "Modifica",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onEdit(row.item)
                                  },
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: { icon: "pencil-square" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.actions.includes("destroy") &&
                        _vm.isVarAccByCode(row.item, "CP")
                          ? _c(
                              "b-button",
                              {
                                staticClass: "mt-1 mr-1",
                                attrs: {
                                  size: "sm",
                                  variant: "lisaweb",
                                  title: "Elimina",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDestroy(row.item.id)
                                  },
                                },
                              },
                              [_c("b-icon", { attrs: { icon: "trash" } })],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "row-details",
                fn: function (row) {
                  return [
                    _c(
                      "b-card",
                      _vm._l(row.item, function (value, key) {
                        return _c(
                          "b-row",
                          { key: key, staticClass: "mb-2" },
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "text-sm-right",
                                attrs: { sm: "3" },
                              },
                              [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(_vm._f("capitalize")(key)) + ":"
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("b-col", [_vm._v(_vm._s(value))]),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            true
          ),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }